import React from "react"
import Layout from "../components/layout"
import ContactUs from '../components/contact'
import SEO from "../components/seo"

const SamplesPage = () => (
  <Layout page="404">
    <SEO title="Rethink Main - Samples" />
    <h1>Hold On</h1>
    <p>This page is coming soon</p>
    <ContactUs />
  </Layout>
)

export default SamplesPage
